@import "../../../styles/variables";

.join {
  h1 {
    margin-bottom: 26px;
  }
  .notice {
    font-size:   10px;
    margin-left: 10px;
  }
  .form {
    max-width: 500px;
    label {
      max-width: 340px;
    }
    .wallet {
      border-bottom: none;
      padding:       0 10px;
      margin-bottom: 20px;
      :global {
        .error {
          bottom: -14px;
        }
      }
    }
    .am {
      max-width:     340px;
      padding:       0 0 12px 6px;
      /*border-bottom: 1px solid rgba($yellow, 50%);*/
      margin:        14px 0 16px 0;
      position:      relative;
      p {
        display:     flex;
        align-items: center;
        color:       rgba(white, 50%);
        img {
          width:        18px;
          margin-right: 8px;
        }
      }
    }
    .question {
      position:      relative;
      z-index:       1;
      width:         25px;
      min-width:     25px;
      margin-left:   10px;
      padding-right: 10px;
      display:       flex;
      align-items:   center;
      &:hover {
        .tooltip {
          display: block;
        }
      }
      .tooltip {
        display:       none;
        width:         228px;
        position:      absolute;
        z-index:       1;
        left:          24px;
        top:           10px;
        border:        1px solid $yellow;
        padding:       12px 20px;
        border-radius: 25px 25px 25px 0;
        color:         white;
        font-size:     14px;
        line-height:   18px;
        font-weight:   500;
        background:    black;
        transform:     translate(0, -100%);
        a {
          font-size:       12px;
          font-weight:     900;
          color:           $yellow;
          text-decoration: none;
        }
      }
    }
  }
}

@media screen and (max-width: 567px) {
  .join {
    .form {
      .question {
        .tooltip {
          border-radius: 25px 25px 0 25px;
          right:         20px;
          top:           -5px;
          left:          inherit;
        }
      }
    }
    .button {
      text-align: center;
    }
  }
}